<template>
  <v-layout row wrap>
    <v-flex xs12 sm12 md12 lg6 xl6>
      <div v-if="packet.version === 1">🔋{{ computeBatVolt(p?.vbat) }}V  ⛽️ {{ p?.ibat }}mA 🔋🌡{{ computeBattTemp(p?.batTemp) }}ºC </div>
      <div v-else-if="packet.version === 2">🔋{{ computeBatVoltVer2(p?.byte_1) }}V  ⛽️ {{ computeBattCurrentVer2(p) }}mA </div>
    </v-flex>
    <v-flex xs12 sm12 md12 lg6 xl6>
      <div v-if="packet.version === 1">
        <strong>Angle X/Y/Z:</strong> {{p?.giroX}}/{{p?.giroY}}/{{p?.giroZ}}
      </div>
      <div v-else-if="packet.version === 2">
        <strong>Angle X/Y/Z:</strong> {{ computeGyroVersion2(`${p.byte_9}${p.byte_10}`) }}/{{ computeGyroVersion2(`${p.byte_11}${p.byte_12}`) }}/{{ computeGyroVersion2(`${p.byte_13}${p.byte_14}`) }}
      </div>
    </v-flex>
  </v-layout>

</template>

<script>

export default {
  setup(props) {
  },
  components: {
  },
  props: [
    "packet",
  ],
  methods: {
    round(x) {
      if (x)
        return Math.round(x);
      return null;
    },
    fixed2(x) {
      if (x)
        return x.toFixed(2);
      return null;
    },
    fixed3(x) {
      if (x)
        return x.toFixed(3);
      return null;
    },
    toHex(data) {
      if (data.startsWith("VGlueUdTLXRlc3Q"))
        return "OndoGS Test Packet"

      if (data.startsWith("RXJyb3JfQ1JD"))
        return "CRC ERROR"

      var decodedData = Buffer.from(data, 'base64')
      let packetData = "";
      for (var i = 0; i < decodedData.length; i++) {
          if (decodedData[i] <= 0xF) { packetData += "0"; }
          else { packetData += ""; }
          packetData += decodedData[i].toString(16) + "";
      }
      return packetData;
    },
    computeBatVolt(batVolt) {
      if (!batVolt) {
        return ''
      }

      const batteryVoltage = (batVolt + 72.7) / 75
      return batteryVoltage.toFixed(2);
    },
    computeBatAmper(batAmper) {
      if (!batAmper) return ''

      return (batAmper * 51.6 -6668).toFixed(2)
    },
    computeBattTemp(battTemp) {
      try {
        let temp = 131.3 - 28.43 * Math.log(battTemp)
        return temp.toFixed(2);
      }
      catch(err) {
        return ''
      }
    },
    /** VERSION 2 */
    computeBatVoltVer2(batVolt) {
      try {
        const batteryVoltage = (parseInt(batVolt, 16) * 2.5 * 2) / 255
        return batteryVoltage.toFixed(2);
      }
      catch (err) {
        return ''
      }
    },
    computeBattCurrentVer2(packet) {
      try {
        if (packet?.byte_70 === "00") {
          let value =  packet?.byte_71; // Empty for byte "00"
          return parseInt(value, 16)
        } else if (/^0[1-9]$/.test(packet?.byte_70)) {
          let value = `${parseInt(packet?.byte_70, 10)}${packet?.byte_71}`
          return parseInt(value, 16)
        } else if (/^0[A-F]$/.test(packet?.byte_70)) {
          let value = `${parseInt(packet?.byte_70, 16)}${packet?.byte_71}`
          return parseInt(value, 16)
        } else if (/^[A-F]$/.test(packet?.byte_70)) {
          let value = `${parseInt(packet?.byte_70, 16)}${packet?.byte_71}`
          return parseInt(value, 16)
        }
        let value = `${packet?.byte_70}${packet?.byte_71}`
        return parseInt(value, 16)
      }
      catch (err) {
        return ''
      }
    },
    computeGyroVersion2(gyro) {
      return gyro
      return parseInt(gyro, 16)
    }
  },
  computed: {
    /**
     * Payload
     */
    p() {
      let packet = this.packet;
      if (packet && packet.payload)
        return packet.payload
      return null
    },
  }
}
</script>

<style>

</style>
