<template>
  <div class="satellites">
    <v-container class="my-5">
      <v-container v-if="user" fluid>
        <v-row justify="end">
          <v-sheet class="ma-8">
            <v-btn color="primary" @click="openModal">
              Create satellite
            </v-btn>
          </v-sheet>
        </v-row>
      </v-container>

      <v-row class="ml-1">
        <v-col cols="12" sm="12" md="4" lg="3" class="mt-1">
          <v-text-field v-model="findTxt" hide-details outlined dense clearable label="Find"
            prepend-inner-icon="mdi-magnify"></v-text-field>
        </v-col>
        <v-col cols="12" sm="12" md="4" lg="3">
          <v-select v-model="statusSelect" :items="statusItems" label="Status" multiple chips hide-details clearable dense
            prepend-inner-icon="mdi-filter-menu" :menu-props="{ bottom: true, offsetY: true }">
            <template v-slot:selection="data">
              <v-chip :class="data.item" :key="JSON.stringify(data.item)" v-bind="data.attrs" :input-value="data.selected"
                @click:close="statusSelect.splice(statusSelect.indexOf(data.item), 1)" close>
                {{ data.item }}
              </v-chip>
            </template>
          </v-select>
        </v-col>
        <v-col cols="12" class="d-none d-md-block" md="4" lg="3">
          <v-select v-model="freqSelect" :items="freqItems" label="Frequency" multiple hide-details chips clearable dense
            prepend-inner-icon="mdi-filter-menu" :menu-props="{ bottom: true, offsetY: true }">
            <template v-slot:selection="data">
              <v-chip :class="data.item" :key="JSON.stringify(data.item)" v-bind="data.attrs" :input-value="data.selected"
                @click:close="freqSelect.splice(freqSelect.indexOf(data.item), 1)" close>
                {{ data.item }}
              </v-chip>
            </template>
          </v-select>
        </v-col>
      </v-row>
      <v-layout row wrap>
        <v-flex xs12 sm6 md4 lg3 v-for="sat in satellites" :key="sat.name">
          <v-card class="text-center ma-4 rounded-lg clickable" :to="`satellite/${sat._id}`">
            <v-img height="250" :src="getImageUrl(sat)">
              <template v-slot:placeholder>
                <v-row class="fill-height ma-0" align="center" justify="center">
                  <v-progress-circular indeterminate color="grey"></v-progress-circular>
                </v-row>
              </template>
            </v-img>
            <v-card-title class="ms-auto justify-end" v-if="sat.packetCount">
              <v-icon
                small
              >
                mdi-satellite-variant
              </v-icon>
              <small class="ml-2 gray--text">{{ sat.packetCount }}</small>
            </v-card-title>
            <!--<v-responsive class="pt-4">{{ sat.images[0] }}</v-responsive>-->
            <v-card-text>
              <div class="subheading">{{ sat.name }}</div>
              <div class="grey--text">{{ sat.description }}</div>
              <div class="mt-3" v-if="sat.status == 'Future'"><strong>Launch Date:</strong>
                {{ formatLaunchDate(sat.launchDate) }}</div>
              <div class="mt-3" v-if="sat.lastpackettime"><strong>Last Packet:</strong>
                  {{ formatLaunchDate(sat.lastpackettime) }}</div>
            </v-card-text>
            <v-chip v-if="sat.configuration?.mode && sat.configuration?.freq">
              {{ sat.configuration.mode }}@{{ sat.configuration.freq }}
            </v-chip>
            <v-chip class="ma-2">Version: {{ sat.version }}</v-chip>
            <v-chip class="black--text ma-2" :color="statusColor(sat.status)">{{ sat.status }}</v-chip>
            <v-card-actions>

            </v-card-actions>
          </v-card>
        </v-flex>
      </v-layout>

      <CreateSatellite :showDialog="showDialog" @closeDialog="closeModal" />

    </v-container>
  </div>
</template>

<script>
import store from '../store';
import { useApi } from "../hooks/useApi"

import { dateToMyTimeZone } from '../utils/myDate';

import CreateSatellite from './satellite/CreateSatellite.vue';

const satApi = useApi().satellite

const SATELLITE_STATUS = {
  "Supperted": "green",
  "Inactive": "red",
  "Future": "blue",
  "Experimental": "yellow"
}

export default {
  name: 'Satellites',
  components: {
    CreateSatellite
  },
  data() {
    return {
      user: {},
      showDialog: false,

      findTxt: "",
      statusSelect: [],
      statusItems: ['Supported', 'Future', 'Experimental', 'Inactive'],
      freqSelect: [],
      freqItems: ['433', '868-915', '2400'],

      origData: []
    }
  },
  beforeMount() {
    this.user = store.state.auth.user
    this.getSatellites();
  },
  methods: {
    async getSatellites() {
      const select = 'version name description status images'
      const { success, data } = await satApi.get(select)
      if (success)
        this.origData = data;
    },
    formatLaunchDate(date) {
      return dateToMyTimeZone(date);
    },
    openModal() {
      this.showDialog = true
    },
    closeModal() {
      this.showDialog = false
      this.getSatellites();
    },
    statusColor(status) {
      let color = SATELLITE_STATUS[status]
      return color || "grey"
    }
  },
  computed: {
    satellites() {
      if (!this.origData)
        return null;

      let sats;
      if (this.findTxt) {
        sats = this.origData.filter(s => s.name.toLowerCase().includes(this.findTxt.toLowerCase())
          || s.name.toLowerCase().includes(this.findTxt.toLowerCase())
          || s.description.toLowerCase().includes(this.findTxt.toLowerCase()))
      }
      else
        sats = this.origData

      if (this.statusSelect.length > 0)
        sats = sats.filter(sat => this.statusSelect.includes(sat.status))

      if (this.freqSelect.length > 0) {
        let freq_min, freq_max;
        if (this.freqSelect == '433') {
          freq_min = 400; freq_max = 550;
        } else if (this.freqSelect == '868-915') {
          freq_min = 800; freq_max = 950;
        } else if (this.freqSelect == '2400') {
          freq_min = 2100; freq_max = 2500;
        }

        sats = sats
          .filter(
            sat =>
              freq_min <= (sat.configuration?.freq ? parseFloat(sat.configuration?.freq) : 0)
              && (sat.configuration?.freq ? parseFloat(sat.configuration?.freq) : 0) <= freq_max
          )
        // sats = sats.filter(sat => sat.configurations.filter(conf => freq_min < conf.freq && conf.freq < freq_max).length > 0)
      }

      return sats
    },
    getImageUrl() {
      return function (sat) {
        var imagePath = ''
        if (sat.images && sat.images[0]) {
          imagePath = process.env.VUE_APP_API_BASE_URL + sat.images[0]
        }

        return imagePath
      };
    }
  }
}

</script>

<style>
.subheading {
  margin: 10px 20px;
}

.clickable:hover {
  top: -5px;
}
</style>
