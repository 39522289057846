<template>
  <div>
    <v-card flat class="mr-5 my-3 pa-2">
      <v-card-text class="mx-auto">
        <h1 class="ma-2 mb-5">{{ packet.satellite.name }}</h1>
        <div>Received on: {{ formatDate(packet.serverTime) }}</div>
        <div>
          Mode: <i>{{ packet.satellite?.configuration?.mode }}</i> {{ packet.satellite?.configuration?.freq }} Mhz  SF: {{ packet.satellite?.configuration?.sf }}  CR: {{ packet.satellite?.configuration?.cr }} BW: {{ packet.satellite?.configuration?.bw }} kHz BW: {{ packet.satellite?.configuration?.bw }} kHz
        </div>
        <div v-if="packet.payload">
          <br />
          <DynamicTemplate class="pa-2" :packet="packet" />
          <v-layout row wrap>
            <v-flex pa-2 xs6 md6 lg4 xl3>
              <div>🔄 OBC reset: {{ parseInt(payload?.byte_3, 16) }}</div>
              <div>⬆️ Last CMD: {{ payload?.byte_4 }}</div>
              <div>RF SF: {{ parseInt(payload?.byte_46, 16) }}</div>
              <div v-if="payload?.byte_47 === '01'">RF Bandwidth: 250KHz</div>
              <div v-else-if="payload?.byte_47 === '02'">RF Bandwidth: 125KHz</div>
              <div v-else-if="payload?.byte_47 === '03'">RF Bandwidth: 62KHz</div>
              <div>Data collect interval: {{ parseInt(payload?.byte_48, 16) }}</div>
            </v-flex>
            <v-flex pa-2 xs6 md6 lg4 xl3>
              <div>Packet byte 1: {{ payload?.byte_5 }}</div>
              <div>Packet byte 2: {{ payload?.byte_6 }}</div>
              <div>Packet byte 3: {{ payload?.byte_7 }}</div>
            </v-flex>
            <v-flex pa-2 xs6 md6 lg4 xl3>
              <div>ADCS state 1: {{ payload?.byte_8 }}</div>
              <div>ADCS state 2: {{ payload?.byte_9 }}</div>
              <div>gyro X: {{ gyroX(payload?.byte_10, payload?.byte_11) }}</div>
              <div>gyro Y: {{ gyroY(payload?.byte_12, payload?.byte_13) }}</div>
              <div>gyro Z: {{ gyroZ(payload?.byte_14, payload?.byte_15) }}</div>
            </v-flex>
            <v-flex pa-2 xs6 md6 lg4 xl3>
              <div>acc X: {{ accX(payload?.byte_16, payload?.byte_17) }}</div>
              <div>acc Y: {{ accY(payload?.byte_18, payload?.byte_19) }}</div>
              <div>acc Z: {{ accZ(payload?.byte_20, payload?.byte_21) }}</div>

            </v-flex>
            <v-flex pa-2 xs6 md6 lg4 xl3>
              <div>magX: {{ magX(payload?.byte_24, payload?.byte_25) }}</div>
              <div>magY: {{ magY(payload?.byte_26, payload?.byte_27) }}</div>
              <div>magZ: {{ magZ(payload?.byte_28, payload?.byte_29) }}</div>

              <div><br /></div>

              <div>sun +X: {{ sunPX(payload?.byte_30, payload?.byte_31) }}</div>
              <div>sun -X: {{ sunNX(payload?.byte_32, payload?.byte_33) }}</div>
              <div>sun +Y: {{ sunPY(payload?.byte_34, payload?.byte_35) }}</div>
              <div>sun -Y: {{ sunNY(payload?.byte_36, payload?.byte_37) }}</div>
              <!-- <div>sun +Z: {{ sunPZ(payload?.byte_38, payload?.byte_39) }}</div> -->
              <div>sun -Z: {{ sunNZ(payload?.byte_22, payload?.byte_23) }}</div>
            </v-flex>
            <v-flex pa-2 xs6 md6 lg4 xl3>
              <div>GPS lat_h: {{ payload?.byte_39 }}</div>
              <div>GPS lat_l: {{ payload?.byte_40 }}</div>
              <div>GPS long_h: {{ payload?.byte_41 }}</div>
              <div>GPS long_l: {{ payload?.byte_42 }}</div>
              <div>GPS sign: {{ payload?.byte_43 }}</div>
              <div>GPS hour: {{ payload?.byte_44 }}</div>
              <div>GPS minute: {{ payload?.byte_45 }}</div>
            </v-flex>
            <v-flex pa-2 xs6 md6 lg4 xl3>
              <div>SP4-CURRENT: {{ eps0(payload?.byte_49) }}</div>
              <div>RAW-PWR-VOLTAGE: {{ eps1(payload?.byte_50) }}</div>
              <div>SP4-VOLTAGE: {{ eps1(payload?.byte_51) }}</div>
              <div>sp3-voltage: {{ eps1(payload?.byte_52) }}</div>
              <div>SP2-VOLTAGE: {{ eps1(payload?.byte_53) }}</div>
              <div>SP1-VOLTAGE: {{ eps1(payload?.byte_54) }}</div>
              <div>BATT_PWR_VOLTAGE: {{ eps1(payload?.byte_55) }}</div>
              <div>I-SENS-BAT: {{ eps7(payload?.byte_56) }}</div>
              <div>I-SENS-DU: {{ eps7(payload?.byte_57) }}</div>
              <div>SP2-CURRENT: {{ eps0(payload?.byte_58) }}</div>
              <div>SP1-CURRENT: {{ eps0(payload?.byte_59) }}</div>
            </v-flex>
            <v-flex pa-2 xs6 md6 lg4 xl3>
              <div>I-SENS-7V5: {{ eps11(payload?.byte_60) }}</div>
              <div>I-SENS-3V3-2: {{ eps11(payload?.byte_61) }}</div>
              <div>I-SENS-5V0: {{ eps11(payload?.byte_62) }}</div>
              <div>I-SENS-3V3-1: {{ eps11(payload?.byte_63) }}</div>
              <div>SP3-CURRENT: {{ eps0(payload?.byte_64) }}</div>
              <div>I-SENS-UNREG: {{ eps16(payload?.byte_65) }}</div>
              <div>DAY-TIME: {{ payload?.byte_66 }}</div>
              <div>HOUR-TIME: {{ payload?.byte_67 }}</div>
              <div>MIN-TIME: {{ payload?.byte_68 }}</div>
              <div>SEC-TIME: {{ payload?.byte_69 }}</div>

              <div>Total DU Current: {{ duCurrent(payload?.byte_70, payload?.byte_71) }}</div>
            </v-flex>
          </v-layout>
        </div>
        <div v-else>
          <strong>Unrecognized packet.</strong> This packet does not match the known structure of this satellite. It might be an unknown packet or just terrestrial noise.
        </div>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
  import DynamicTemplate from '@components/DynamicTemplate.vue';

  import { dateToMyTimeZone } from '@utils/myDate';

  export default {
    name: "PacketVersion2",
    props: ['packet'],
    components: {
      DynamicTemplate,
    },
    methods: {
      formatDate(time) {
        return dateToMyTimeZone(time);
      },
      base64ToHex(str) {
        const raw = atob(str);
        let result = '';
        for (let i = 0; i < raw.length; i++) {
          const hex = raw.charCodeAt(i).toString(16);
          result += " " + (hex.length === 2 ? hex : '0' + hex);
        }
        return result
      },
      computeAntDeployAttempt(antDeployAttempt) {
        if (!antDeployAttempt) {
          return
        }
        return parseInt(antDeployAttempt, 2);
      },
      computeRFInterval(rfInterval) {
        if (!rfInterval) return

        let _rfInterval = ''
        try {
          _rfInterval = parseInt(parseInt(rfInterval, 2)) * 2
        } catch (err) {}

        return _rfInterval
      },
      computeBoardTemp(boardTemp) {
        try {
          return boardTemp - 100;
        }
        catch {
          return ''
        }
      },
      eps0(ADC_value_8bit) {
        try
        {
          let value = (0.0051 * parseInt(ADC_value_8bit, 16)) + 0.0111
          value = value.toFixed(2)
          return `${value}A`
        }
        catch {
          return ''
        }
      },
      eps1(ADC_value_8bit) {
        try
        {
          let value = (parseInt(ADC_value_8bit, 16) * 2.5 * 2) / 255
          value = value.toFixed(2)
          return `${value}V`
        }
        catch {
          return ''
        }
      },
      eps7(ADC_value_8bit) {
        try
        {
          let value = (51.6 * parseInt(ADC_value_8bit, 16) - 6668)
          value = value.toFixed(2)
          return `${value}mA`
        }
        catch {
          return ''
        }
      },
      eps11(ADC_value_8bit) {
        try
        {

          let value = 0.0081 * parseInt(ADC_value_8bit, 16) - 0.0002
          value = value.toFixed(2)
          return `${value}A`
        }
        catch {
          return ''
        }
      },
      eps16(ADC_value_8bit) {
        try
        {
          let value = (0.01 * parseInt(ADC_value_8bit, 16))  + 0.0078
          value = value.toFixed(2)
          return `${value}A`
        }
        catch {
          return ''
        }
      },
      gyroX: (gyro_x1, gyro_x2) => {
      // Combine the two bytes into a 16-bit value
        const combinedValue = (((gyro_x1 & 0x00FF) << 8) | gyro_x2 & 0x00FF) & 0x0FFF;
        const result = combinedValue / 10;

        const isPositive = (gyro_x1 && 0x80) === 0x80

        if (!isPositive) {
          return result * -1
        }

        return result
      },
      gyroY: (gyro_y1, gyro_y2) => {
      // Combine the two bytes into a 16-bit value
        const combinedValue = (((gyro_y1 & 0x00FF) << 8) | gyro_y2 & 0x00FF) & 0x0FFF;
        const result = combinedValue / 10;

        const isPositive = (gyro_y1 && 0x80) === 0x80

        if (!isPositive) {
          return result * -1
        }

        return result
      },
      gyroZ: (gyro_x1, gyro_z2) => {
      // Combine the two bytes into a 16-bit value
        const combinedValue = (((gyro_x1 & 0x00FF) << 8) | gyro_z2 & 0x00FF) & 0x0FFF;
        const result = combinedValue / 10;

        const isPositive = (gyro_x1 && 0x80) === 0x80

        if (!isPositive) {
          return result * -1
        }

        return result
      },
      accX(x_1, x_2) {
        try {
          const combinedValue = (((x_1 & 0x00FF) << 8) | x_2 & 0x00FF) & 0x0FFF;
          const result = combinedValue / 100;

          const isPositive = (x_1 & 0x80) === 0x80

          if (!isPositive) {
            return result * -1
          }

          return result
        }
        catch {
          return ''
        }
      },
      accY(y_1, y_2) {
        try {
          const combinedValue = (((y_1 & 0x00FF) << 8) | y_2 & 0x00FF) & 0x0FFF;
          const result = combinedValue / 100;

          const isPositive = (y_1 & 0x80) === 0x80

          if (!isPositive) {
            return result * -1
          }

          return result
        }
        catch {
          return ''
        }
      },
      accZ(z_1, z_2) {
        try {
          const combinedValue = (((z_1 & 0x00FF) << 8) | z_2 & 0x00FF) & 0x0FFF;
          const result = combinedValue / 100;

          const isPositive = (z_1 & 0x80) === 0x80

          if (!isPositive) {
            return result * -1
          }

          return result
        }
        catch {
          return ''
        }
      },
      magX(mag_x1, mag_x2) {
        const combinedValue = (((mag_x1 & 0x00FF) << 8) | mag_x2 & 0x00FF) & 0x0FFF;
        const result = combinedValue / 5;

        const isPositive = (mag_x1 & 0x80) === 0x80

        if (!isPositive) {
            return result * -1
        }

        return result
      },
      magY(mag_y1, mag_y2) {
        const combinedValue = (((mag_y1 & 0x00FF) << 8) | mag_y2 & 0x00FF) & 0x0FFF;
        const result = combinedValue / 5;

        const isPositive = (mag_y1 & 0x80) === 0x80

        if (!isPositive) {
          return result * -1
        }

        return result
      },
      magZ(mag_z1, mag_z2) {
        const combinedValue = (((mag_z1 & 0x00FF) << 8) | mag_z2 & 0x00FF) & 0x0FFF;
        const result = combinedValue / 5;

        const isPositive = (mag_z1 & 0x80) === 0x80

        if (!isPositive) {
          return result * -1
        }

        return result
      },
      sunPX(sun_x1, sun_x2) {
        const combinedValue = (((sun_x1 & 0x00FF) << 8) | sun_x2 & 0x00FF) & 0x0FFF;
        return combinedValue
      },
      sunNX(sun_x1, sun_x2) {
        const combinedValue = (((sun_x1 & 0x00FF) << 8) | sun_x2 & 0x00FF) & 0x0FFF;
        return combinedValue
      },
      sunPY(sun_y1, sun_y2) {
        const combinedValue = (((sun_y1 & 0x00FF) << 8) | sun_y2 & 0x00FF) & 0x0FFF;
        return combinedValue
      },
      sunNY(sun_y1, sun_y2) {
        const combinedValue = (((sun_y1 & 0x00FF) << 8) | sun_y2 & 0x00FF) & 0x0FFF;
        return combinedValue
      },
      sunPZ(sun_z1, sun_z2) {
        const combinedValue = (((sun_z1 & 0x00FF) << 8) | sun_z2 & 0x00FF) & 0x0FFF;
        return combinedValue
      },
      sunNZ(sun_z1, sun_z2) {
        const combinedValue = (((sun_z1 & 0x00FF) << 8) | sun_z2 & 0x00FF) & 0x0FFF;
        return combinedValue
      },
      duCurrent: (du_1, du_2) => {
        const combinedValue = (((du_1 & 0x00FF) << 8) | du_2 & 0x00FF) & 0x0FFF;
        return combinedValue
      },
    },
    computed: {
      base64ToHexDecimal() {
        return `data:application/octet-stream;base64,${this.packet.raw}`
      },
      payload() {
        let packet = this.packet;
        if (packet && packet.payload)
          return packet.payload
        return null
      },
    },
  }
</script>

<style>
  .jv-container .jv-code {
    padding: 4px 10px;
  }

  .download-btn {
    position:absolute;
    right:20px;
    text-decoration: none;
    color:rgb(84, 176, 212) !important;
  }
</style>
