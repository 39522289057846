<template>
  <v-dialog v-model="modal" max-width="540px">
    <template v-slot:activator="{ on }">
      <v-btn class="mt-2 primary" slot="activator" v-on="on">Dynamic commands</v-btn>
    </template>

    <v-card>
      <v-card-title class="d-flex justify-space-between align-center">
        <div class="text-h5 text-medium-emphasis">
          Dynamic commands
        </div>
        <v-btn density="compact" icon @click="hanldeCloseModal">
          <v-icon>
            mdi-close
          </v-icon>
        </v-btn>
      </v-card-title>
      <v-card-text class="text-center">
        <!-- Only hex -->
        <v-select v-model="satelliteId" :items="satellites" item-text="name" item-value="id" label="Select a satellite*"></v-select>
        <!-- NOTE: version 1 satellites -->
        <v-flex v-if="version === 1" >
          <v-flex ref="form">
            <v-col v-if="selectedSatellite?.callSign === 'OWL1'" class="pa-0">
              <v-select
                label="Select a command*"
                v-model="selectedCommand"
                :items="obc1DynamicCommands"
                item-text="commandName"
                item-value="commandId"
              ></v-select>
              <!-- CMD TO MPIC -> SDR FM Memory -->
              <v-flex v-if="selectedCommand === 'OBC-1-22'">
                <v-select
                  label="Satellite ID*"
                  ref="ref_obc_2"
                  :items="[
                    {
                      text: 'AA',
                      value: 'AA',
                    },
                    {
                      text: 'BB',
                      value: 'BB',
                    },
                  ]"
                ></v-select>
                <v-select
                  label="Command byte*"
                  ref="ref_obc_3"
                  :items="[
                    {
                      text: 'C0',
                      value: 'C0',
                    },
                    {
                      text: 'C1',
                      value: 'C1',
                    },
                    {
                      text: 'C2',
                      value: 'C2',
                    },
                    {
                      text: 'C3',
                      value: 'C3',
                    },
                  ]"
                ></v-select>
                <v-text-field ref="ref_obc_5" :rules="[hexRules]" clearable required label="1 byte of 32 byte addr" variant="outlined"></v-text-field>
                <v-text-field ref="ref_obc_6" :rules="[hexRules]" clearable required label="2 byte of 32 byte addr" variant="outlined"></v-text-field>
                <v-text-field ref="ref_obc_7" :rules="[hexRules]" clearable required label="3 byte of 32 byte addr" variant="outlined"></v-text-field>
                <v-text-field ref="ref_obc_8" :rules="[hexRules]" clearable required label="4 byte of 32 byte addr" variant="outlined"></v-text-field>
                <!-- inputs -->
                <v-text-field ref="ref_obc_9" :rules="[hexRules]" clearable required label="byte 1" variant="outlined"></v-text-field>
                <v-text-field ref="ref_obc_10" :rules="[hexRules]" clearable required label="byte 2" variant="outlined"></v-text-field>
                <v-text-field ref="ref_obc_11" :rules="[hexRules]" clearable required label="byte 3" variant="outlined"></v-text-field>
                <v-text-field ref="ref_obc_12" :rules="[hexRules]" clearable required label="byte 4" variant="outlined"></v-text-field>
                <v-text-field ref="ref_obc_13" :rules="[hexRules]" clearable required label="byte 5" variant="outlined"></v-text-field>
                <v-text-field ref="ref_obc_14" :rules="[hexRules]" clearable required label="byte 6" variant="outlined"></v-text-field>
                <v-text-field ref="ref_obc_15" :rules="[hexRules]" clearable required label="byte 7" variant="outlined"></v-text-field>
                <v-text-field ref="ref_obc_16" :rules="[hexRules]" clearable required label="byte 8" variant="outlined"></v-text-field>
                <v-text-field ref="ref_obc_17" :rules="[hexRules]" clearable required label="byte 9" variant="outlined"></v-text-field>
                <v-text-field ref="ref_obc_18" :rules="[hexRules]" clearable required label="byte 10" variant="outlined"></v-text-field>
              </v-flex>
              <!-- <div>
                CMD TO MPIC -> SDR FM Memory
              </div>
              <div>
                <v-select
                  label="Satellite ID"
                  :items="[
                    {
                      text: 'AA',
                      value: 'AA',
                    },
                    {
                      text: 'BB',
                      value: 'BB',
                    }
                  ]"
                ></v-select>
              </div>
              <div>
                <v-select
                  label="Command ID"
                  :items="[
                    {
                      text: 'C0',
                      value: 'C0',
                    },
                    {
                      text: 'C1',
                      value: 'C1',
                    },
                    {
                      text: 'C2',
                      value: 'C2',
                    },
                    {
                      text: 'C3',
                      value: 'C3',
                    },
                  ]"
                ></v-select>
              </div> -->
            </v-col>
            <v-col v-else-if="selectedSatellite?.callSign === 'OWL2'" class="pa-0">
              <!-- <div>OWL-2</div> -->
              <v-select
                label="Select a command*"
                v-model="selectedCommand"
                :items="obc2DynamicCommands"
                item-text="commandName"
                item-value="commandId"
              ></v-select>
              <v-flex v-if="selectedCommand === 'OBC-2-34'">
                <v-select
                  label="Satellite ID*"
                  ref="ref_obc_2"
                  :items="[
                    {
                      text: 'AA',
                      value: 'AA',
                    },
                    {
                      text: 'BB',
                      value: 'BB',
                    },
                  ]"
                ></v-select>
                <v-select
                  label="Command byte*"
                  ref="ref_obc_3"
                  :items="[
                    {
                      text: 'C0',
                      value: 'C0',
                    },
                    {
                      text: 'C1',
                      value: 'C1',
                    },
                    {
                      text: 'C2',
                      value: 'C2',
                    },
                    {
                      text: 'C3',
                      value: 'C3',
                    },
                  ]"
                ></v-select>
                <v-text-field ref="ref_obc_5" :rules="[hexRules]" clearable required label="1 byte of 32 byte addr" variant="outlined"></v-text-field>
                <v-text-field ref="ref_obc_6" :rules="[hexRules]" clearable required label="2 byte of 32 byte addr" variant="outlined"></v-text-field>
                <v-text-field ref="ref_obc_7" :rules="[hexRules]" clearable required label="3 byte of 32 byte addr" variant="outlined"></v-text-field>
                <v-text-field ref="ref_obc_8" :rules="[hexRules]" clearable required label="4 byte of 32 byte addr" variant="outlined"></v-text-field>
                <!-- inputs -->
                <v-text-field ref="ref_obc_9" :rules="[hexRules]" clearable required label="byte 1" variant="outlined"></v-text-field>
                <v-text-field ref="ref_obc_10" :rules="[hexRules]" clearable required label="byte 2" variant="outlined"></v-text-field>
                <v-text-field ref="ref_obc_11" :rules="[hexRules]" clearable required label="byte 3" variant="outlined"></v-text-field>
                <v-text-field ref="ref_obc_12" :rules="[hexRules]" clearable required label="byte 4" variant="outlined"></v-text-field>
                <v-text-field ref="ref_obc_13" :rules="[hexRules]" clearable required label="byte 5" variant="outlined"></v-text-field>
                <v-text-field ref="ref_obc_14" :rules="[hexRules]" clearable required label="byte 6" variant="outlined"></v-text-field>
                <v-text-field ref="ref_obc_15" :rules="[hexRules]" clearable required label="byte 7" variant="outlined"></v-text-field>
                <v-text-field ref="ref_obc_16" :rules="[hexRules]" clearable required label="byte 8" variant="outlined"></v-text-field>
                <v-text-field ref="ref_obc_17" :rules="[hexRules]" clearable required label="byte 9" variant="outlined"></v-text-field>
                <v-text-field ref="ref_obc_18" :rules="[hexRules]" clearable required label="byte 10" variant="outlined"></v-text-field>
              </v-flex>
            </v-col>
            <v-btn class="mt-3" @click="owl1VersionSubmit" :disabled="!satelliteId || !selectedCommand" color="primary">Send the command</v-btn>
          </v-flex>
        </v-flex>
        <!-- NOTE: version 2 satellites -->
        <v-flex v-else-if="version === 2">
          <v-form ref="form">
            <v-select
              label="Select a command*"
              v-model="selectedCommand"
              :items="commands"
              item-text="command"
              item-value="commandId"
            ></v-select>
            <!-- Download packet -->
            <v-flex v-if="selectedCommand === 'A4'">
              <v-text-field ref="byte_1" :rules="[hexRules]" clearable required label="1 byte of 4 byte addr" variant="outlined"></v-text-field>
              <v-text-field ref="byte_2" :rules="[hexRules]" clearable required label="2 byte of 4 byte addr" variant="outlined"></v-text-field>
              <v-text-field ref="byte_3" :rules="[hexRules]" clearable required label="3 byte of 4 byte addr" variant="outlined"></v-text-field>
              <v-text-field ref="byte_4" :rules="[hexRules]" clearable required label="4 byte of 4 byte addr" variant="outlined"></v-text-field>
              <v-text-field ref="byte_5" :rules="[hexRules]" clearable required label="Number of packet" variant="outlined"></v-text-field>
            </v-flex>
            <!-- Download Shared memory packet -->
            <v-flex v-else-if="selectedCommand === 'A7'">
              <v-text-field ref="byte_1" :rules="[hexRules]" clearable required label="1 byte of 4 byte addr" variant="outlined"></v-text-field>
              <v-text-field ref="byte_2" :rules="[hexRules]" clearable required label="2 byte of 4 byte addr" variant="outlined"></v-text-field>
              <v-text-field ref="byte_3" :rules="[hexRules]" clearable required label="3 byte of 4 byte addr" variant="outlined"></v-text-field>
              <v-text-field ref="byte_4" :rules="[hexRules]" clearable required label="4 byte of 4 byte addr" variant="outlined"></v-text-field>
              <v-text-field ref="byte_5" :rules="[hexRules]" clearable required label="Number of packet" variant="outlined"></v-text-field>
            </v-flex>
            <!-- FM write -->
            <v-flex v-else-if="selectedCommand === 'B1'">
              <v-text-field ref="byte_1" :rules="[hexRules]" clearable required label="1 byte of 4 byte addr" variant="outlined"></v-text-field>
              <v-text-field ref="byte_2" :rules="[hexRules]" clearable required label="2 byte of 4 byte addr" variant="outlined"></v-text-field>
              <v-text-field ref="byte_3" :rules="[hexRules]" clearable required label="3 byte of 4 byte addr" variant="outlined"></v-text-field>
              <v-text-field ref="byte_4" :rules="[hexRules]" clearable required label="4 byte of 4 byte addr" variant="outlined"></v-text-field>
              <v-text-field ref="byte_5" :rules="[hexRules]" clearable required label="CMD 1" variant="outlined"></v-text-field>
              <v-text-field ref="byte_6" :rules="[hexRules]" clearable required label="CMD 2" variant="outlined"></v-text-field>
              <v-text-field ref="byte_7" :rules="[hexRules]" clearable required label="CMD 3" variant="outlined"></v-text-field>
              <v-text-field ref="byte_8" :rules="[hexRules]" clearable required label="CMD 4" variant="outlined"></v-text-field>
              <v-text-field ref="byte_9" :rules="[hexRules]" clearable required label="CMD 5" variant="outlined"></v-text-field>
              <v-text-field ref="byte_10" :rules="[hexRules]" clearable required label="CMD 6" variant="outlined"></v-text-field>
              <v-text-field ref="byte_11" :rules="[hexRules]" clearable required label="CMD 7" variant="outlined"></v-text-field>
              <v-text-field ref="byte_12" :rules="[hexRules]" clearable required label="CMD 8" variant="outlined"></v-text-field>
            </v-flex>
            <!-- SDR CMD (CAM mode) -->
            <v-flex v-else-if="selectedCommand === 'B2'">
              <v-text-field ref="byte_1" :rules="[hexRules]" clearable required label="CMD 1" variant="outlined"></v-text-field>
              <v-text-field ref="byte_2" :rules="[hexRules]" clearable required label="CMD 2" variant="outlined"></v-text-field>
              <v-text-field ref="byte_3" :rules="[hexRules]" clearable required label="CMD 3" variant="outlined"></v-text-field>
              <v-text-field ref="byte_4" :rules="[hexRules]" clearable required label="CMD 4" variant="outlined"></v-text-field>
              <v-text-field ref="byte_5" :rules="[hexRules]" clearable required label="CMD 5" variant="outlined"></v-text-field>
              <v-text-field ref="byte_6" :rules="[hexRules]" clearable required label="CMD 6" variant="outlined"></v-text-field>
              <v-text-field ref="byte_7" :rules="[hexRules]" clearable required label="CMD 7" variant="outlined"></v-text-field>
              <v-text-field ref="byte_8" :rules="[hexRules]" clearable required label="CMD 8" variant="outlined"></v-text-field>
              <v-text-field ref="byte_9" :rules="[hexRules]" clearable required label="CMD 9" variant="outlined"></v-text-field>
              <v-text-field ref="byte_10" :rules="[hexRules]" clearable required label="CMD 10" variant="outlined"></v-text-field>
              <v-text-field ref="byte_11" :rules="[hexRules]" clearable required label="CMD 11" variant="outlined"></v-text-field>
              <v-text-field ref="byte_12" :rules="[hexRules]" clearable required label="CMD 12" variant="outlined"></v-text-field>
            </v-flex>
            <!-- Magnetorquer control -->
            <v-flex v-else-if="selectedCommand === 'B3'">
              <v-text-field ref="byte_1" :rules="[hexRules]" clearable required label="DATA 1" variant="outlined"></v-text-field>
              <v-text-field ref="byte_2" :rules="[hexRules]" clearable required label="DATA 2" variant="outlined"></v-text-field>
              <v-text-field ref="byte_3" :rules="[hexRules]" clearable required label="DATA 3" variant="outlined"></v-text-field>
              <v-text-field ref="byte_4" :rules="[hexRules]" clearable required label="DATA 4" variant="outlined"></v-text-field>
              <v-text-field ref="byte_5" :rules="[hexRules]" clearable required label="DATA 5" variant="outlined"></v-text-field>
              <v-text-field ref="byte_6" :rules="[hexRules]" clearable required label="DATA 6" variant="outlined"></v-text-field>
              <v-text-field ref="byte_7" :rules="[hexRules]" clearable required label="DATA 7" variant="outlined"></v-text-field>
              <v-text-field ref="byte_8" :rules="[hexRules]" clearable required label="DATA 8" variant="outlined"></v-text-field>
            </v-flex>
            <!-- TinyGS mode -->
            <v-flex v-else-if="selectedCommand === 'B4'">
              <v-select
                label="Mode"
                v-model="selectedOption"
                :items="[
                  {
                    text: 'Deactive Tiny GS',
                    value: '00',
                  },
                  {
                    text: 'Active TinyGS',
                    value: '01',
                  }
                ]"
              ></v-select>
            </v-flex>
            <!-- Detumble data -->
            <v-flex v-else-if="selectedCommand === 'B5'">
              <v-text-field ref="byte_1" :rules="[hexRules]" clearable required label="DATA 1" variant="outlined"></v-text-field>
              <v-text-field ref="byte_2" :rules="[hexRules]" clearable required label="DATA 2" variant="outlined"></v-text-field>
              <v-text-field ref="byte_3" :rules="[hexRules]" clearable required label="DATA 3" variant="outlined"></v-text-field>
              <v-text-field ref="byte_4" :rules="[hexRules]" clearable required label="DATA 4" variant="outlined"></v-text-field>
              <v-text-field ref="byte_5" :rules="[hexRules]" clearable required label="DATA 5" variant="outlined"></v-text-field>
              <v-text-field ref="byte_6" :rules="[hexRules]" clearable required label="DATA 6" variant="outlined"></v-text-field>
              <v-text-field ref="byte_7" :rules="[hexRules]" clearable required label="DATA 7" variant="outlined"></v-text-field>
              <v-text-field ref="byte_8" :rules="[hexRules]" clearable required label="DATA 8" variant="outlined"></v-text-field>
            </v-flex>
            <!-- Deploy antenna -->
            <v-flex v-else-if="selectedCommand === 'B7'">
              <v-select
                label="Mode"
                v-model="selectedOption"
                :items="[
                  {
                    text: 'Antenna 1',
                    value: '11',
                  },
                  {
                    text: 'Antenna 2',
                    value: '12',
                  },
                  {
                    text: 'Solar 1',
                    value: '13',
                  },
                  {
                    text: 'Solar 2',
                    value: '14',
                  },
                ]"
              ></v-select>
            </v-flex>
            <!-- Set CMD to OBC -->
            <v-flex v-else-if="selectedCommand === 'B8'">
              <v-text-field ref="byte_1" :rules="[hexRules]" clearable required label="CMD 1" variant="outlined"></v-text-field>
              <v-text-field ref="byte_2" :rules="[hexRules]" clearable required label="CMD 2" variant="outlined"></v-text-field>
              <v-text-field ref="byte_3" :rules="[hexRules]" clearable required label="CMD 3" variant="outlined"></v-text-field>
              <v-text-field ref="byte_4" :rules="[hexRules]" clearable required label="CMD 4" variant="outlined"></v-text-field>
              <v-text-field ref="byte_5" :rules="[hexRules]" clearable required label="CMD 5" variant="outlined"></v-text-field>
              <v-text-field ref="byte_6" :rules="[hexRules]" clearable required label="CMD 6" variant="outlined"></v-text-field>
              <v-text-field ref="byte_7" :rules="[hexRules]" clearable required label="CMD 7" variant="outlined"></v-text-field>
              <v-text-field ref="byte_8" :rules="[hexRules]" clearable required label="CMD 8" variant="outlined"></v-text-field>
              <v-text-field ref="byte_9" :rules="[hexRules]" clearable required label="CMD 9" variant="outlined"></v-text-field>
              <v-text-field ref="byte_10" :rules="[hexRules]" clearable required label="CMD 10" variant="outlined"></v-text-field>
              <v-text-field ref="byte_11" :rules="[hexRules]" clearable required label="CMD 11" variant="outlined"></v-text-field>
              <v-text-field ref="byte_12" :rules="[hexRules]" clearable required label="CMD 12" variant="outlined"></v-text-field>
            </v-flex>
            <!-- Add Geofencing -->
            <v-flex v-else-if="selectedCommand === 'B9'">
              <v-text-field ref="byte_1" :rules="[hexRules]" clearable required label="LAT 1" variant="outlined"></v-text-field>
              <v-text-field ref="byte_2" :rules="[hexRules]" clearable required label="LONG 1" variant="outlined"></v-text-field>
              <v-text-field ref="byte_3" :rules="[hexRules]" clearable required label="LAT 2" variant="outlined"></v-text-field>
              <v-text-field ref="byte_4" :rules="[hexRules]" clearable required label="LONG 2" variant="outlined"></v-text-field>
              <v-text-field ref="byte_5" :rules="[hexRules]" clearable required label="LAT 3" variant="outlined"></v-text-field>
              <v-text-field ref="byte_6" :rules="[hexRules]" clearable required label="LONG 3" variant="outlined"></v-text-field>
              <v-text-field ref="byte_7" :rules="[hexRules]" clearable required label="LAT 4" variant="outlined"></v-text-field>
              <v-text-field ref="byte_8" :rules="[hexRules]" clearable required label="LONG 4" variant="outlined"></v-text-field>
            </v-flex>
            <!-- Store SAF data -->
            <v-flex v-else-if="selectedCommand === 'BB'">
              <v-text-field ref="byte_1" :rules="[hexRules]" clearable required label="CMD 1" variant="outlined"></v-text-field>
              <v-text-field ref="byte_2" :rules="[hexRules]" clearable required label="CMD 2" variant="outlined"></v-text-field>
              <v-text-field ref="byte_3" :rules="[hexRules]" clearable required label="CMD 3" variant="outlined"></v-text-field>
              <v-text-field ref="byte_4" :rules="[hexRules]" clearable required label="CMD 4" variant="outlined"></v-text-field>
              <v-text-field ref="byte_5" :rules="[hexRules]" clearable required label="CMD 5" variant="outlined"></v-text-field>
              <v-text-field ref="byte_6" :rules="[hexRules]" clearable required label="CMD 6" variant="outlined"></v-text-field>
              <v-text-field ref="byte_7" :rules="[hexRules]" clearable required label="CMD 7" variant="outlined"></v-text-field>
              <v-text-field ref="byte_8" :rules="[hexRules]" clearable required label="CMD 8" variant="outlined"></v-text-field>
              <v-text-field ref="byte_9" :rules="[hexRules]" clearable required label="CMD 9" variant="outlined"></v-text-field>
              <v-text-field ref="byte_10" :rules="[hexRules]" clearable required label="CMD 10" variant="outlined"></v-text-field>
              <v-text-field ref="byte_11" :rules="[hexRules]" clearable required label="CMD 11" variant="outlined"></v-text-field>
              <v-text-field ref="byte_12" :rules="[hexRules]" clearable required label="CMD 12" variant="outlined"></v-text-field>
            </v-flex>
            <!-- Detumble auto -->
            <v-flex v-else-if="selectedCommand === 'BC'">
              <v-text-field ref="byte_1" :rules="[hexRules]" clearable required label="DATA 1" variant="outlined"></v-text-field>
              <v-text-field ref="byte_2" :rules="[hexRules]" clearable required label="DATA 2" variant="outlined"></v-text-field>
              <v-text-field ref="byte_3" :rules="[hexRules]" clearable required label="DATA 3" variant="outlined"></v-text-field>
              <v-text-field ref="byte_4" :rules="[hexRules]" clearable required label="DATA 4" variant="outlined"></v-text-field>
              <v-text-field ref="byte_5" :rules="[hexRules]" clearable required label="DATA 5" variant="outlined"></v-text-field>
              <v-text-field ref="byte_6" :rules="[hexRules]" clearable required label="DATA 6" variant="outlined"></v-text-field>
              <v-text-field ref="byte_7" :rules="[hexRules]" clearable required label="DATA 7" variant="outlined"></v-text-field>
              <v-text-field ref="byte_8" :rules="[hexRules]" clearable required label="DATA 8" variant="outlined"></v-text-field>
            </v-flex>
            <!-- Change RF interval -->
            <v-flex v-else-if="selectedCommand === 'C1'">
              <v-text-field ref="byte_1" :rules="[hexRules]" clearable required label="Interval data" variant="outlined"></v-text-field>
            </v-flex>
            <!-- Change RF config -->
            <v-flex v-else-if="selectedCommand === 'C2'">
              <v-text-field ref="byte_1" :rules="[hexRules]" clearable required label="Bandwidth" variant="outlined"></v-text-field>
              <v-text-field ref="byte_2" :rules="[hexRules]" clearable required label="SF" variant="outlined"></v-text-field>
            </v-flex>
            <!-- Change RF Encryption -->
            <v-flex v-else-if="selectedCommand === 'C3'">
              <v-select
                label="Mode"
                v-model="selectedOption"
                :items="[
                  {
                    text: 'OFF',
                    value: '00',
                  },
                  {
                    text: 'ON',
                    value: '01',
                  }
                ]"
              ></v-select>
            </v-flex>
            <!-- RF Frequence config -->
            <v-flex v-else-if="selectedCommand === 'C4'">
              <v-select
                label="Mode"
                v-model="selectedOption"
                :items="[
                  {
                    text: '400.162Mhz',
                    value: '04',
                  },
                  {
                    text: '920Mhz',
                    value: '09',
                  }
                ]"
              ></v-select>
            </v-flex>
            <!-- Change RF Frequency to manual -->
            <v-flex v-else-if="selectedCommand === 'C5'">
              <v-text-field ref="byte_1" :rules="[hexRules]" clearable required label="Data 1" variant="outlined"></v-text-field>
              <v-text-field ref="byte_2" :rules="[hexRules]" clearable required label="Data 2" variant="outlined"></v-text-field>
              <v-text-field ref="byte_3" :rules="[hexRules]" clearable required label="Data 3" variant="outlined"></v-text-field>
              <v-text-field ref="byte_4" :rules="[hexRules]" clearable required label="Data 4" variant="outlined"></v-text-field>
            </v-flex>
            <!-- Change Detumble auto MODE -->
            <v-flex v-else-if="selectedCommand === 'C6'">
              <v-select
                label="Mode"
                v-model="selectedOption"
                :items="[
                  {
                    text: 'OFF',
                    value: '00',
                  },
                  {
                    text: 'ON',
                    value: '01',
                  }
                ]"
              ></v-select>
            </v-flex>
            <!-- Sector erase -->
            <v-flex v-else-if="selectedCommand === 'D1'">
              <v-text-field ref="byte_1" :rules="[hexRules]" clearable required label="1 byte of 4 byte addr" variant="outlined"></v-text-field>
              <v-text-field ref="byte_2" :rules="[hexRules]" clearable required label="2 byte of 4 byte addr" variant="outlined"></v-text-field>
              <v-text-field ref="byte_3" :rules="[hexRules]" clearable required label="3 byte of 4 byte addr" variant="outlined"></v-text-field>
              <v-text-field ref="byte_4" :rules="[hexRules]" clearable required label="4 byte of 4 byte addr" variant="outlined"></v-text-field>
            </v-flex>
            <!-- Subsector erase -->
            <v-flex v-else-if="selectedCommand === 'D2'">
              <v-text-field ref="byte_1" :rules="[hexRules]" clearable required label="1 byte of 4 byte addr" variant="outlined"></v-text-field>
              <v-text-field ref="byte_2" :rules="[hexRules]" clearable required label="2 byte of 4 byte addr" variant="outlined"></v-text-field>
              <v-text-field ref="byte_3" :rules="[hexRules]" clearable required label="3 byte of 4 byte addr" variant="outlined"></v-text-field>
              <v-text-field ref="byte_4" :rules="[hexRules]" clearable required label="4 byte of 4 byte addr" variant="outlined"></v-text-field>
            </v-flex>
            <!-- Sector erase of Shared memory -->
            <v-flex v-else-if="selectedCommand === 'D3'">
              <v-text-field ref="byte_1" :rules="[hexRules]" clearable required label="1 byte of 4 byte addr" variant="outlined"></v-text-field>
              <v-text-field ref="byte_2" :rules="[hexRules]" clearable required label="2 byte of 4 byte addr" variant="outlined"></v-text-field>
              <v-text-field ref="byte_3" :rules="[hexRules]" clearable required label="3 byte of 4 byte addr" variant="outlined"></v-text-field>
              <v-text-field ref="byte_4" :rules="[hexRules]" clearable required label="4 byte of 4 byte addr" variant="outlined"></v-text-field>
            </v-flex>
            <v-btn class="mt-3" @click="submit" :disabled="!satelliteId || !selectedCommand" color="primary">Send the command</v-btn>
          </v-form>
        </v-flex>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import { useApi } from '@hooks/useApi';

const API = useApi()


export default {
  name: "DynamicCommand",
  props: [
    "station",
    "modemConf",
    "satellites"
  ],
  data() {
    return {
      modal: null,
      satelliteId: '',
      config: '',
      selectedSatellite: null,
      selectedCommand: null,

      commands: [],
      obc1DynamicCommands: [],
      obc2DynamicCommands: [],

      version: null,
      selectedOption: null,  // select option command-тай command-д ашиглана

      hexRules: (value) => {
        this.selectedOption = null;
        if (value && value.length <= 2 && this.isHex(value))
        {
          if (this.selectedCommand === "C1")
          {
            if (parseInt(value, 16) < parseInt("05", 16))
            {
              return "Can not be less than 5 minute"
            }
          }
          return true;
        }
        return 'The input value must be hex'
      },
    }
  },
  beforeMount() {
    // this.getVersion2Satellites();
    this.getDynamicCommands();
    this.getObc1DynamicCommands();
    this.getObc2DynamicCommands();
  },
  methods: {
    isHex(text) {
      const hexRegex = /^[0-9A-Fa-f]{2}$/;
      return hexRegex.test(text);
    },
    async getDynamicCommands() {
      const { success, data } = await API.command.get("dynamic");
      if (success)
        this.commands = data
    },
    async getObc1DynamicCommands() {
      const { success, data } = await API.command.getObc1Commands("dynamic");
      if (success)
        this.obc1DynamicCommands = data
    },
    async getObc2DynamicCommands() {
      const { success, data } = await API.command.getObc2Commands("dynamic");
      if (success)
        this.obc2DynamicCommands = data
    },
    async submit() {
      let byte_1 = this.$refs.byte_1?.internalValue ?? "00";
      let byte_2 = this.$refs.byte_2?.internalValue ?? "00";
      let byte_3 = this.$refs.byte_3?.internalValue ?? "00";
      let byte_4 = this.$refs.byte_4?.internalValue ?? "00";
      let byte_5 = this.$refs.byte_5?.internalValue ?? "00";
      let byte_6 = this.$refs.byte_6?.internalValue ?? "00";
      let byte_7 = this.$refs.byte_7?.internalValue ?? "00";
      let byte_8 = this.$refs.byte_8?.internalValue ?? "00";
      let byte_9 = this.$refs.byte_9?.internalValue ?? "00";
      let byte_10 = this.$refs.byte_10?.internalValue ?? "00";
      let byte_11 = this.$refs.byte_11?.internalValue ?? "00";
      let byte_12 = this.$refs.byte_12?.internalValue ?? "00";

      let params = {
        satelliteId: this.satelliteId,
        commandId: this.selectedCommand,
        byte_1,
        byte_2,
        byte_3,
        byte_4,
        byte_5,
        byte_6,
        byte_7,
        byte_8,
        byte_9,
        byte_10,
        byte_11,
        byte_12,
      }

      // select option command сонгосон бол зөвхөн тухайн утгыг byte_1 дээр явуулна. Арын утгууд 00 байх ёстой
      if (this.selectedOption) {
        params = {
          satelliteId: this.satelliteId,
          commandId: this.selectedCommand,
          byte_1: this.selectedOption,
          byte_2: "00",
          byte_3: "00",
          byte_4: "00",
          byte_5: "00",
          byte_6: "00",
          byte_7: "00",
          byte_8: "00",
          byte_9: "00",
          byte_10: "00",
          byte_11: "00",
          byte_12: "00",
        }
      }

      if (!this.satelliteId)
      {
        this.$toast("Select a satellite", {
          timeout: 3000,
          type: "warning",
        })
        return
      }
      try {
        const { success } = await API.station.sendDynamicCommand(this.$route.params.id, params)
        if (success)
        {
          this.$toast("The command was sent", {
            timeout: 3000,
            type: "success"
          });
        }
        else
        {
          this.$toast("Not found", {
            timeout: 3000,
            type: "warning"
          });
        }
      }
      catch (err) {
        console.log(JSON.stringify(err))
        this.$toast("An error occurred while sending the command", {
          timeout: 3000,
          type: "error"
        });
      }
      this.hanldeCloseModal()
    },
    async owl1VersionSubmit() {
      if (!this.satelliteId)
      {
        this.$toast("Select a satellite", {
          timeout: 3000,
          type: "warning",
        })
        return
      }

      let byte_1 = this.selectedCommand === "OBC-1-22" ? "66" : "OBC-2-34" ? "96" : "00";
      let byte_2 = this.$refs.ref_obc_2?.internalValue ?? "00";
      let byte_3 = this.$refs.ref_obc_3?.internalValue ?? "00";
      let byte_4 = "84";
      let byte_5 = this.$refs.ref_obc_5?.internalValue ?? "00";
      let byte_6 = this.$refs.ref_obc_6?.internalValue ?? "00";
      let byte_7 = this.$refs.ref_obc_7?.internalValue ?? "00";
      let byte_8 = this.$refs.ref_obc_8?.internalValue ?? "00";
      let byte_9 = this.$refs.ref_obc_9?.internalValue ?? "00";
      let byte_10 = this.$refs.ref_obc_10?.internalValue ?? "00";
      let byte_11 = this.$refs.ref_obc_11?.internalValue ?? "00";
      let byte_12 = this.$refs.ref_obc_12?.internalValue ?? "00";
      let byte_13 = this.$refs.ref_obc_13?.internalValue ?? "00";
      let byte_14 = this.$refs.ref_obc_14?.internalValue?? "00";
      let byte_15 = this.$refs.ref_obc_15?.internalValue?? "00";
      let byte_16 = this.$refs.ref_obc_16?.internalValue?? "00";
      let byte_17 = this.$refs.ref_obc_17?.internalValue?? "00";
      let byte_18 = this.$refs.ref_obc_18?.internalValue?? "00";

      let command = `${byte_1}${byte_2}${byte_3}${byte_4}${byte_5}${byte_6}${byte_7}${byte_8}${byte_9}${byte_10}${byte_11}${byte_12}${byte_13}${byte_14}${byte_15}${byte_16}${byte_17}${byte_18}`;
      let params = {
        satelliteId: this.satelliteId,
        commandId: this.selectedCommand,
        command,
      }
      try {
        const { success } = await API.station.sendDynamicCommandOwl1(this.$route.params.id, params)
        if (success)
        {
          this.$toast("The command was sent", {
            timeout: 3000,
            type: "success"
          });
        }
        else
        {
          this.$toast("Not found", {
            timeout: 3000,
            type: "warning"
          });
        }
      }
      catch (err) {
        this.$toast("An error occurred while sending the command", {
          timeout: 3000,
          type: "error"
        });
      }
      this.hanldeCloseModal()
    },
    hanldeCloseModal() {
      this.satelliteId = null,
      this.selectedSatellite = null
      this.selectedCommand = null
      this.$emit("sent");
      this.modal = false
    }
  },
  watch: {
    satelliteId () {
      let satellite = this.satellites.find(satellite => satellite.id === this.satelliteId)
      this.selectedSatellite = satellite
      this.version = satellite?.version
    },
    selectedCommand () {
      this.$refs.byte_1?.reset()
      this.$refs.byte_2?.reset()
      this.$refs.byte_3?.reset()
      this.$refs.byte_4?.reset()
      this.$refs.byte_5?.reset()
      this.$refs.byte_6?.reset()
      this.$refs.byte_7?.reset()
      this.$refs.byte_8?.reset()
      this.$refs.byte_9?.reset()
      this.$refs.byte_10?.reset()
      this.$refs.byte_11?.reset()
      this.$refs.byte_12?.reset()

      this.$refs.ref_obc_1?.reset()
      this.$refs.ref_obc_2?.reset()
      this.$refs.ref_obc_3?.reset()
      this.$refs.ref_obc_4?.reset()
      this.$refs.ref_obc_5?.reset()
      this.$refs.ref_obc_6?.reset()
      this.$refs.ref_obc_7?.reset()
      this.$refs.ref_obc_8?.reset()
      this.$refs.ref_obc_9?.reset()
      this.$refs.ref_obc_10?.reset()
      this.$refs.ref_obc_11?.reset()
      this.$refs.ref_obc_12?.reset()
      this.$refs.ref_obc_13?.reset()
      this.$refs.ref_obc_14?.reset()
      this.$refs.ref_obc_15?.reset()
      this.$refs.ref_obc_16?.reset()
      this.$refs.ref_obc_17?.reset()
      this.$refs.ref_obc_18?.reset()
    },
  },
}
</script>

<style>

</style>
